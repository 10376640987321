import React from 'react';
import { M31PopularProductsModule, ProductCarouselModuleLayoutEnum } from '~/lib/data-contract';
import { ModuleContainer } from '../ModuleContainer';
import { usePopularProducts } from './hooks/usePopularProducts';
import { ProductCollectionLayout } from '~/shared/components/ProductCollectionLayout/ProductCollectionLayout';

export const M31PopularProducts = ({
    headline,
    subheadline,
    productCategories = [],
    callToAction,
    contentLayout,
    pageSize = '6',
    ...rest
}: M31PopularProductsModule) => {
    // Page size only matter for the column layout. All products are fetched but only the virtalPageSize is shown before load more.
    const virtualPageSize =
        contentLayout === ProductCarouselModuleLayoutEnum.CAROUSEL ? 999 : parseInt(pageSize);

    const { hasNextPage, fetchNextPage, products } = usePopularProducts({
        pageSize: virtualPageSize,
        categories: productCategories,
        displayedAtLocation: 'M31PopularProducts',
    });

    return (
        <ModuleContainer fullWidth {...rest}>
            <ProductCollectionLayout
                headline={headline}
                subheadline={subheadline}
                callToAction={callToAction}
                contentLayout={contentLayout}
                showLoadMore={hasNextPage}
                onLoadMoreClick={fetchNextPage}
                products={products}
            />
        </ModuleContainer>
    );
};
