import {
    dataFieldValueContainsFilter,
    Product,
    getProductProperties,
    productVariantProperties,
    specificProductCategoryIdsFilter,
} from '$templates/blocks/components/M140ProductsList';
import {
    PopularProductsRequest,
    FilterCollection,
    SelectedVariantPropertiesSettings,
    SelectedProductPropertiesSettings,
} from '@relewise/client';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { fetcher } from '~/lib/fetcher';
import { useFrame } from '~/shared/utils';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { usePage } from '~/templates/pages';

interface RecommendationResponseType {
    recommendations: Product[];
}

interface FetchProductsParams {
    locale: string;
    filters: unknown[];
    currency?: string;
    displayedAtLocation?: string;
}

export const fetchProducts = async ({
    filters,
    locale,
    currency,
    displayedAtLocation,
}: FetchProductsParams) => {
    const { RELEWISE_API_URL, RELEWISE_ENVIRONMENT_ID, RELEWISE_SEARCH_API_KEY } =
        publicRuntimeConfig();
    const productProperties = getProductProperties(locale);

    const popularProductsRequest: Partial<PopularProductsRequest> = {
        displayedAtLocationType: displayedAtLocation,
        basedOn: 'MostViewed',
        filters: {
            items: [
                dataFieldValueContainsFilter({
                    key: 'PublishStatus',
                    target: 'product',
                    matchType: 'Any',
                    value: ['published'],
                    equals: true,
                }),
                ...filters,
            ],
        } as FilterCollection,
        currency: {
            value: currency ?? '',
        },
        user: {}, // Most popular products does not need the user to be set
        language: {
            value: locale,
        },
        settings: {
            numberOfRecommendations: 10, // Seems to have no effect on actual result count
            allowFillIfNecessaryToReachNumberOfRecommendations: false,
            allowReplacingOfRecentlyShownRecommendations: false,
            prioritizeDiversityBetweenRequests: false,
            recommendVariant: false,
            selectedProductProperties:
                productProperties as unknown as SelectedProductPropertiesSettings,
            selectedVariantProperties:
                productVariantProperties as SelectedVariantPropertiesSettings,
        },
    };

    return fetcher<RecommendationResponseType>(
        `${RELEWISE_API_URL}/${RELEWISE_ENVIRONMENT_ID}/v1/PopularProductsRequest`,
        {},
        { Authorization: `APIKey ${RELEWISE_SEARCH_API_KEY}` },
        'POST',
        JSON.stringify(popularProductsRequest),
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
};

export const getQueryKey = ({ categories }: { categories: string[] }) => [
    'M31RelewiseProducts',
    categories.join(','),
];

export const getQueryOptions = () => ({
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 30, // 30 minutes cache time
    staleTime: 1000 * 60 * 30, // 30 minutes cache time
    retryOnMount: false,
    enabled: true,
});

export const usePopularProducts = ({
    pageSize,
    categories = [],
    displayedAtLocation,
}: {
    pageSize: number;
    categories: string[];
    displayedAtLocation?: string;
}) => {
    const { market, culture } = usePage();
    const { data: frame } = useFrame();
    const locale = `${culture}-${market}`.toLowerCase();
    const [page, setPage] = useState(1);
    const maxProducts = 9;

    const queryKey = useMemo(() => {
        return getQueryKey({ categories });
    }, [categories]);

    const { data, isLoading, ...restQuery } = useQuery(queryKey, {
        ...getQueryOptions(),
        queryFn: async () => {
            const filters = categories.length ? [specificProductCategoryIdsFilter(categories)] : [];
            const params = {
                locale: locale,
                filters,
                currency: `${frame?.currency}-${culture.toUpperCase()}-${market}`,
                displayedAtLocation,
            };
            return await fetchProducts(params);
        },
    });

    const totalHits = useMemo(() => {
        return Math.min(data?.recommendations.length ?? 0, maxProducts);
    }, [data]);

    const productList = useMemo(() => {
        const products = data?.recommendations ?? [];
        const productsFromPages = pageSize * page;
        const productsToReturn = Math.min(productsFromPages, maxProducts);
        return products.slice(0, productsToReturn);
    }, [data?.recommendations, pageSize, page]);

    const fetchNextPage = () => {
        setPage(page + 1);
    };

    const hasNextPage = useMemo(() => {
        return totalHits > pageSize * page;
    }, [page, totalHits, pageSize]);

    const isNoResults = totalHits == 0 && !isLoading;

    return {
        isLoading,
        ...restQuery,
        isNoResults,
        hasNextPage,
        fetchNextPage,
        totalHits,
        products: productList,
    };
};
